@import "../../../styles/variables";

.ErrorCont{
  background-color: $main-red;
  animation-name: errorInOut;
  animation-duration: 5s;
  animation-delay: .5s;
  position: fixed;
  right: -100%;
  top: 2%;
  transition: 2s;
  border-radius: 25px;
  padding: 5px 20px;
}
@keyframes errorInOut {
  0%{
    right: 0%;
  }
  5%{
    right: 10%;
  }
  80%{
    right: 10%;
  }
  100%{
    right: -100%;
  }
}
$main-green: #33a937;
$main-yellow: #f9eb1d;
$main-blue: #293ef9;
$main-grey: #575757;
$light-black: #454545;
$light-grey: #f5f5f5;
$bg-light-grey: #f6f6f6;
$white: #fff;
$grey: #9e9e9e;
$text-grey: #8E8E8E;
$exo2: "Exo 2", sans-serif;
$secondary-yellow: #f7e14b;
$hover-btn-yellow: #fff8ce;
$soft-yellow: #FDF8B8;
$main-red: #E83131;
$light-green: #F5FFF5D9;
$hover-light-green: #43C23C;
$soft-secondary-yellow: #FFFCF5;
$soft-secondary-grey: #EFEFEF;
$soft-green: #E1FAE1;
$dark-green: #00881D;
$color-text-dark: rgba(87, 87, 87, 1);
$default-box-shadow: 0px 4px 15px 0px rgba(170, 170, 170, 0.1);

@function setlh($fz, $lh) {
  @return calc($lh / $fz);
}

%list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@import "../../../styles/variables";

.notification__noActive {
    right: -320px;
}

.notification__active {
    right: 15px;
}

.noneNotification {
    z-index: 0;
}

.notification {
    z-index: 5;
}

.notification,
.noneNotification {
    position: absolute;
    min-height: 70px;
    width: 100%;
    right: 0;
    top: 0;
    overflow: hidden;
    &__active,
    &__noActive {
        color: #fff;
        max-width: 300px;
        width: 100%;
        min-height: 70px;
        padding: 14px 15px 14px 20px;
        box-shadow: 0px 4px 20px 0px rgba(91, 91, 91, 0.14);
        position: absolute;
        transition: 0.3s;
        border-radius: 34px;
        background-color: $main-green;
        font-size: 20px;
        font-weight: 800;
        line-height: 90%;
        text-transform: uppercase;
        display: flex;
        justify-content: space-evenly;
        p {
            margin: 0;
        }
        .message {
            text-align: center;
        }
    }
    &__logo {
        width: 34px;
        height: 40px;
    }
    &__title {
        max-width: 200px;
        font-size: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
    }
}

@import '../../../styles/variables';

.header{
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 14px rgba(189, 189, 189, 0.15);
  background-color: white;
  position: absolute;
  // z-index: 2;
}

@import "../../../styles/variables";

p {
  @media(max-width: 1100px) {
    font-size: 16px;
  }
  @media(max-width: 840px) {
    font-size: 14px;
  }
}

button{
  border: none;
  outline: none;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 10px 12px;
  text-transform: uppercase;
  color: $main-grey;
  font-family: "Exo 2";
  font-weight: 700;
  cursor: pointer;
  @media(max-width: 1100px) {
    gap: 0px;
  }
}
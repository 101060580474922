@import "../../../styles/variables";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $main-yellow;
  color: $main-grey;
  font-family: $exo2;
  font-size: 14px;
  font-weight: 600;
  line-height: setlh(14, 15.4);
  text-transform: uppercase;
  border: none;
  border-radius: 78px;
  padding: 21.5px 0;
  line-height: 110%;
  transition: 0.3s;
  width: 100%;
  &:hover {
    cursor: pointer;
    color: $main-yellow;
    background: $main-grey;
  }
  &:disabled {
    cursor: default;
    background: #efeeeb;
    color: #9e9e9e;
  }
}

@import "../../../styles/variables";

.button {
  width: 100%;
  padding: 21.5px 0;
  background-color: $soft-secondary-yellow;
  border: 1px solid $main-yellow;
  outline: none;
  border-radius: 78px;
  text-transform: uppercase;
  color: $main-grey;
  font-family: $exo2;
  font-size: 14px;
  font-weight: 600;
  line-height: 110%;
}

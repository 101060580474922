@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Exo 2";
}

*::-webkit-scrollbar {
  border-color: transparent;
  background-color: transparent;
  display: none;
}

*::-webkit-scrollbar-thumb {
  border-color: transparent;
  background-color: transparent;
  display: none;
}

*::-webkit-scrollbar-track {
  border-color: transparent;
  background-color: transparent;
  display: none;
}
*::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-color: transparent;
  background-color: transparent;
  display: none;
}

*::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent;
  background-color: transparent;
  display: none;
}
/* Down */
*::-webkit-scrollbar-button:single-button:vertical:increment {
  border-color: transparent;
  background-color: transparent;
  display: none;
}

*::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: transparent;
  background-color: transparent;
  display: none;
}
*::-webkit-scrollbar-button:single-button {
  border-color: transparent;
  background-color: transparent;
  display: none;
}
/* Up */
*::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-color: transparent;
  background-color: transparent;
  display: none;
}

*::-we html {
  scroll-behavior: smooth;
}

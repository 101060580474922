@import "../../../styles/variables";
.search{
  width: 100%;
  background-color: rgba(0, 0, 0, 0.86);
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  &__header{
    padding: 14px 20px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 14px;
    div {
      background-color: $light-grey;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 10px;
      border-radius: 60px;
    }
    &-input{
      width: 90%;
      background-color: $light-grey;
      border: none;
      outline: none;
      border-radius: 60px;
      font-size: 18px;
      font-weight: 700;
      color: $main-grey;
      &::placeholder{
        color: $grey;

        font-weight: 700;
        font-size: 18px;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
}
    }
    &-btn{
      width: 44px;
      height: 44px;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 7px;
      padding: 20px;
      box-sizing: border-box;
      background-color: $light-grey;
      background-image:url("../../../assets/icon/ico-close1.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
    }
  }
  &__cardList{
    width: 50%;
    min-height: 50vh;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    overflow-x: hidden;
    &-loading{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 0;
      z-index: 4;
    }
  }
}
@import "../../../styles/variables";

.orderList{
  width: 100%;
  direction: rtl;
  overflow-y: scroll;
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  &__paginationBtn{
    cursor: pointer;
    text-transform: uppercase;
    padding: 12px 14px;
    font-size: 14px;
    font-weight: 700;
    width: 92%;
    border-radius: 18px;
    background-color: $main-yellow;
    color: $main-grey;
  }
}

::-webkit-scrollbar {
  width: 10px; 
  height: 10px;
  background-color: white !important;
  border-radius: 10px;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: $grey;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:active {
  background-color: $dark-green;
  border-radius: 10px;
}
@import "../../../styles/variables";
.tabs{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 14px;
  @media(max-width: 1100px) {
    gap: 10px;
  }
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    gap: 5px;
    background-color: $soft-yellow;
    border: none;
    outline: none;
    border-radius: 60px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    color: $text-grey;
    cursor: pointer;
    @media(max-width: 1100px) {
      font-size: 16px;
    }
    @media(max-width: 930px) {
      font-size: 14px;
      padding: 8px 12px;
    }
  }
  .active{
    background-color: $main-yellow;
    color: $main-grey;
  }
}
.watchIco{
  width: 22px;
  height: 20px;
  margin-right: 3px;
}
.watchIcoActive{
  width: 22px;
  height: 20px;
  margin-right: 2px;
  margin-left: 1px;
}

.TabletSearch{
  &__searchBtn{
    background-color: $light-grey !important;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 12px !important;
    &-ico{
      width: 20px;
      height: 20px;
      margin: 0px;
      padding: 0px;
    }
  }
}
.activeFireIcon{
  width: 18px;
  height: 19px;
  margin-right: 3px;
  fill: $main-red;
}
.fireIcon{
  width: 18px;
  height: 19px;
  margin-right: 3px;
  fill: $grey;
  width: 18px;
}

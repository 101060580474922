
.tableCell-buttonCheck,
.tableCell-buttonChecked {
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 7px;
  padding: 0px;
  background-size: 44px;
  cursor: pointer;
}
.tableCell-buttonCheck {
  background-image: url("../../../assets/icon/ico-checkbox.svg");
}
.tableCell-buttonChecked {
  background-image: url("../../../assets/icon/ico-checkbox-checked.svg");
}
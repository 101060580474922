@import "../../styles/variables";

.logo {
  width: 136px;
  height: 69px;
  fill: #ffffff;
  color: white;
  path {
    fill: white;
  }
}

input {
  width: 100%;
  font-size: 20px;
  padding: 20px 18px;
  border: 1px solid #dddcda;
  color: $main-grey;
  border-radius: 16px;
  font-weight: 500;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $text-grey !important;
    font-weight: 500;
  }
}

.container {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  .loader{
    position:absolute;
    top: 40%;
    left:50%;
    animation: animatec 1s linear infinite;  
    div {
      display: flex;
      justify-content: center;
      align-items: center;
  
    }
  }
}

@keyframes animatec{
  0%{
      transform: rotate(0deg);
  }
  100%{
      transform: rotate(360deg);
  }
}
@import "../../styles/variables";

.popup {
  margin: 0;
  padding: 40px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.37);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 32;
  display: flex;
  align-items: center;
  justify-content: center;

  @media all and (max-width: 450px) {
    padding: 105px 12px 40px;
  }
  
  &__modal {
    width: auto;
    height: auto;
    background-color: white;
    display: flex;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    &__content {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  .btnClose {
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0);
    background-image: url("../../assets/icon/ico-cross.svg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

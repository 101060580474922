@import '../../styles/variables';

.main{
  width: 100%;
  height: 100vh;
  background-color: $soft-secondary-grey;
  overflow: hidden;
  text-transform: uppercase;
  &__content, &__content-none{
    display: flex;
    padding-top: 70px;
    &-leftBlock{
      width: 38%;
      height: 91%;
      padding: 20px 0px 20px 16px;
    }
    &-rightBlock{
      width: 62%;
      height: calc(100vh - 70px);
      background-color: #FFF;
    }
  }
  &__content-none {
    opacity: 0.7;
  }
}